@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lilita+One&family=Oswald:wght@200..700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container-hide {
  display: none;
  /* Initially hide the container */
}

.container-show {
  display: block;
  /* Show the container when 'visible' class is applied */
}

.pole-show {
  display: block;
  /* Show the container when 'visible' class is applied */
}

.pole-hide {
  display: none;
  /* Show the container when 'visible' class is applied */
}

.custom-icon {
  display: none;
  /* Initially hide the container */
}
.form-inline>input {
  border-radius: 0 !important;
  border: 1px solid #000;
  font-size: 14px;
  padding: 15px 35px !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  width: 100%;
}
.form-inline>input:focus {
  box-shadow: none;
  outline: 0;
}
span.toggle-label {
  margin-left: 3px;
}
#root {
  overflow-x: hidden;
}
body > iframe {
  z-index: 9 !important;
}
.main-content {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
.custom-tooltip {
  background-color: #f9f9f9;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
}
.leaflet-header, .leaflet-body, .leaflet-footer {
  padding: 15px;
}
.leaflet-body {
  clear: both;
}
.leaflet-body p {
  font-size: 14px;margin-top: 0;
  margin-bottom: 5px;
}
.leaflet-header {
  border-bottom: 1px solid #ddd;
}
.leaflet-footer {
  border-top: 1px solid #ddd;
}
.leaflet-popup-close-button {
  font-size: 24px;
    margin: 10px;
}
.modal-thumb { 
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  float: left;
}
.info-wrap {
  display: inline-block;
}
.modal-thumb > img {
  max-width: 90px;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.leaflet-popup-content {
  margin: 0 !important;
  width: 100% !important;
  min-width: 320px;
  max-width: 100%;
}
.leaflet-header > h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.btn-custom {
  border: 0;
  white-space: nowrap;
  padding: 7px 15px;
  border-radius: 15px;
  display: inline-block;
  background: #427d9d;
  color: #ddf2fd;
  margin: 0 1px;
  font-size: 13px;
  line-height: 1;
  transition: all 300ms ease-in-out;
}
.btn-custom:hover {
  background-color: #164863;
  color: #fff;
}
.btn-custom:focus, .btn-bordered:focus, .btn-custom:active, .btn-bordered:active{ outline: none;}
.btn-bordered {
  border: 1px solid #427d9d;
    white-space: nowrap;
    padding: 7px 15px;
    border-radius: 5px;
    display: inline-block;
    background: #ddf2fd;
    color: #427d9d;
    margin: 0 1px;
    font-size: 13px;
    line-height: 1;
}
.info-wrap h6 {
  font-weight: 600;
  font-size: 16px;
}
table.diagram-tbl-chart img {
  width: 26px;
}
/* Sidebar.css */
.leftsidebar-container.hidden .main-menu {
  /* transform: translateX(-120px);  */
  margin-left: -120px;
  /* Move sidebar off-screen when hidden */
}
.leftsidebar-container.visible .main-menu {
  /* transform: translateX(0);  */
  margin-left: 0;
  /* Move sidebar back on-screen when visible */
}
.element-list {
  max-height: 90%;
  overflow-y: auto;
}

.home-switch {
  position: absolute;
  top: 65px;
  background-color: #6B8A7A;
  left: 10px;
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 888;
}

.toggle-switch{
  position: absolute;
  top: 10px;
  /* background-color: #164863; */
  background-color: #6B8A7A;
  left: 10px;
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999;
}
.toggle-switch:focus {
  box-shadow: none;
  outline: 0;
}
.load-map-btn {
  padding: 10px 20px;
  /* background: #164863; */
  background-color: #6B8A7A;
  color: #fff;
  /* margin: 10px 0; */
  border-radius: 30px;
  font-size: 14px;
}
.load-map-btn:disabled {
  background: #cdcdcd;
  cursor: not-allowed;
}
.sidebar-visible{
  position: relative;
}
.sidebar .main-menu {
  max-width: 100px;
  width: 92px;
  position: relative;
  z-index: 9999;
  /* background: #427D9D; */
  background-color: #6B8A7A;
  height: calc(100vh - 80px);
  padding: 10px 0px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  overflow-y: auto;
  /* overflow-x: hidden; */
}
/* .sidebar.visible + .main-content { */
  /* margin-left: 120px; */
/* } */
.sidebar.hidden + .main-content {
  margin-left: 0;
}
.sidebar.hidden .sub-sidebar-wrap {
  /* transform: translateX(-250px); */
  margin-left: -250px;
}
.sidebar button{
  border: none;
}
.bars-icon{
  /* width: 35px; */
  max-width: 32px;
  border: none;
}
/* .leftsidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 120px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
} */

/* ul li {
    width: 180px;
    height: auto;
    display: flex;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.5em;
    background: rgb(43, 43, 43);
    position: relative;
    color: white;
    border-radius: 5px;
} */


/* Add more styling as needed */

.leftsidebar,
.rightsidebar { 
  display: flex;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;}
.leftsidebar-menu,
.rightsidebar-menu {
  padding-left: 0;
  list-style-type: none;
  text-align: left;
  margin-bottom: 0;
}
.leftsidebar-menu .sidebar-icon,
.rightsidebar-menu .sidebar-icon {
  margin-bottom: 10px;
  /* filter: grayscale(1); */
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
}
.leftsidebar-menu > li,
.rightsidebar-menu > li { 
  border-bottom: 1px solid rgb(221 242 253 / 40%);
}
.leftsidebar-menu > li > a,
.rightsidebar-menu > li > a { 
  text-decoration: none;
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 25px 10px;
  display: block;
}
.leftsidebar-menu > li > a > span,
.rightsidebar-menu > li > a > span,
.leftsidebar-menu > li >  span,
.rightsidebar-menu > li >  span {
  text-decoration: none;
  color: #DDF2FD;
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.leftsidebar-menu > li.active .sidebar-icon,
.leftsidebar-menu > li:hover .sidebar-icon,
.rightsidebar-menu > li.active .sidebar-icon,
.rightsidebar-menu > li:hover .sidebar-icon {
  filter: grayscale(0);
}
.leftsidebar-menu > li.active a > span,
.leftsidebar-menu > li:hover a > span,
.rightsidebar-menu > li.active a > span,
.rightsidebar-menu > li:hover a > span  {
  color: #DDF2FD;
}
.leftsidebar-menu > li > a::before, 
.rightsidebar-menu > li > a::before  {
  position: absolute;
  content: "";
  width:5px;
  border-radius: 10px;
  background-color: #DDF2FD;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 80%;
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.rightsidebar-menu >li > a::before {
  left: unset;
  right: 0;
}
.leftsidebar-menu > li.active > a::before, 
.rightsidebar-menu > li.active > a::before {
  opacity: 1;
}
.sub-sidebar-wrap {
  width: 250px;
  /* background: #DDF2FD; */
  background: #faffff;
  z-index: 999;
  position: relative;
  top: 0;
  /* left: 120px; */
  /* transform: translateX(-250px); */
  border-left: 1px solid #f3f3f3;
  box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
  height: calc(100vh - 80px);
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  overflow-y: auto;
  opacity: 0;
  margin-left: -250px;
}
.leftsidebar .sub-sidebar-wrap {
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.rightsidebar .sub-sidebar-wrap {
  right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.leftsidebar .sub-sidebar-wrap.visible {
  left: 0;
  margin-left: 0;
  /* width: 250px; */
  opacity: 1;
}
.rightsidebar .sub-sidebar-wrap.visible {
  right: 0;
  margin-left: 0;
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 1;
}
.sub-menu > ul > li {
  padding: 2px 0;
}
.sub-menu > ul > li > a, .sub-menu > ul > li > li > a { 
  font-size: 14px;
  color: #164863;
  display: block;
  font-weight: 400;
  position: relative;
  text-decoration: none;
  padding: 10px 5px;
  border-radius: 7px;
}
.sub-menu ul li > a:hover {
  color: #427D9D;
}

/* Accordion css */
.accordion-content {
  height: 0;
  padding-left: 25px;
  position: static;
  transition: height 300ms ease-in-out;
  -webkit-transition: height 300ms ease-in-out;
  -o-transition: height 300ms ease-in-out;
  -moz-transition: height 300ms ease-in-out;
  overflow: hidden;
}
/* .sub-sidebar-wrap .accordion { */
  /* margin-bottom: 10px; */
/* } */
.accordion .accordion-header {
  display: flex;
  cursor: pointer;
  padding: 5px 0;
  justify-content: space-between;
}
.accordion .accordion-header .toggle{
  width: 100%;
}
.accordion .accordion-header h3 {
  width: 100%;
 font-size: 14px;
 font-weight: 400;
 margin-bottom: 0;
 color: #164863;
 white-space: nowrap;
 overflow: hidden;
}
.accordion .accordion-header h3 > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
}
.accordion .accordion-header:hover h3 {
  color: #427D9D;
}
.sub-menu .accordion.open .accordion-content {
  height: auto;
}
.accordion .arrow {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.accordion .arrow {
  background-image: url('/chevron.png');
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
}
.accordion.open .arrow.open {
  transform: rotate(90deg);
  transform-origin: center;
}
.sub-menu .accordion-content ul {
  padding-left: 0;
  margin-bottom: 15px;
  padding-top: 7px;
}
.sub-menu .accordion-content ul li a,.sub-menu ul li div a , .sub-menu ul li li a {
  text-decoration: none;
  color: #427D9D;
  font-size: 14px;
  padding: 4px 5px;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  line-height: 1.4;
  margin-bottom: 2px;
}

.sub-menu .accordion-content ul li{

  display: flex;
  justify-content: space-between;

}

.sub-menu .accordion-content ul li a:hover {
  color: #164863;
}
.sub-menu .accordion-content ul li a.active,.sub-menu ul li div a.active, .sub-menu > ul > li > a.active,.sub-menu > ul > li > li > a.active, .sub-menu .accordion-content ul li.active, .sub-menu > ul > li.active,.sub-menu ul li li a.active {
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
  padding: 5px 15px 5px 15px;

}

.accordion-content div{
  margin-top: 10px;

}
.accordion-content div  a.active{
  /* background-color: black; */
  width:100%;
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
  padding: 5px 15px 5px 15px;
}

.sub-menu > ul > li > div > a, .sub-menu > ul > li > a.active, .sub-menu ul li li a.active{
width: 100%;
margin-right: 5px;
  padding: 8px 40px 8px 8px;

}
.sub-menu .accordion-content ul li a.active::before,.sub-menu > ul > li > li > a.active::before
/* .sub-menu > ul > li > a.active::before  */
{
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  left: -18px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #164863;
}
.sub-menu > ul > li > a.active::before {
  left: -10px;
}
button.close-submenu {
  /* position: absolute; */
  margin: 30px auto 10px;
  background-color: transparent;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  border: 1px solid #9BBEC8;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
button.close-submenu:hover {opacity: 1;}
.rightsidebar {
  position: relative;
  right: 0;
  top: 0;
  z-index: 99999999;
  background: #fff;
}
.rightsidebar .sub-sidebar-wrap.sub-menu.visible + .main-menu {
  border-left: 1px solid #DDF2FD;
}
.leftsidebar .sub-sidebar-wrap li > a > img, .accordion-content > ul > li > a > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 10px;
}
button.close-submenu > img {
  display: block;
  margin: 0 auto;
  width: 25px;
}
.leftsidebar-container.hidden .sub-menu.visible{
  margin-left: -250px;
}
.sub-menu-title {
  border-bottom: 1px solid rgb(155 190 200 / 50%);
  padding-bottom: 15px;
}
.sub-menu-child {
  border-bottom: 1px solid rgb(155 190 200 / 50%);
  padding-bottom: 10px;
}

.sub-menu-child .label-name{
  color: #586376;
  font-size: 12px;
  font-weight: 600;
}

span.selected-item-label {
  font-size: 11px;
    color: #818181;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline-block;
    /* width: 63px; */
    white-space: nowrap;
    /* overflow: hidden; */
    padding-right: 0px;
    position: relative;
    margin-left: 3px;
}
/* span.selected-item-label::after {
  position: absolute;
  content: "";
  font-size: 11px;
  height: 100%;
  width: 15px;
  background-color: #DDF2FD;
  color: #818181;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto; opacity: 0;
} */

.diagram-tbl-chart {
  width: 90%;
}
.diagram-tbl-chart td {
  padding: 0 5px;
  font-size: 12px;
}
.diagram-tbl-chart > tbody>tr>td:nth-child(2) {
  width: 35%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-block {
  display: block;
}

.tableData {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
}

.tdData, .thData {
  font-size: 15px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.trData:nth-child(even) {
  background-color: #dddddd;
}
.form-label1{
  width: 100%;
  text-align: left;
}


/* REFRESH  */

.refresh-icon {
  font-size: 1.5rem; /* Adjust size as needed */
  color: #333; /* Icon color */
}

.refresh-icon-container {
  cursor: pointer;
  
}

.refresh-icon-container.spin {
  animation: spin 1s infinite linear; /* Animation duration 1 second */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Modal container */
.modal-overlay {
    position: fixed;
    top: 200px;
    left: 350px;
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* border: 10px solid black;; */
    display: flex;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    z-index: 1000001;
  }
  
  .modal-overlay .modal-content {
    background-color: rgb(255, 255, 255);
    /* border:20px solid black; */

    border-radius: 5px;
    width: 250px;
    max-width: 300px;
    /* height: 550px; */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: bounceRight 0.5s forwards;
  }
  
  /* Animation for sliding in from the left and bouncing */
  @keyframes bounceRight {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(20px);
    }
    80% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* Close button */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
  }


  
.modal-overlay .modal-content .container .card .card-body{

  text-align: left;
}



/* Drop Modal css  */

@keyframes vibrate {
  0% { transform: translate(0); }
  25% { transform: translate(-2px, 0); }
  50% { transform: translate(2px, 0); }
  75% { transform: translate(-2px, 0); }
  100% { transform: translate(0); }
}

.vibrate {
  animation: vibrate 0.5s ease-in-out;
}

.modal-upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone {
  height:120px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.dropzone .active {
  border-color: green;
}

.uploaded-files-list {
  display: 'flex';
  flex-wrap: 'no-wrap';
  margin-top: 15px;
}

.uploaded-files-list ul {
  list-style: none;
  
}

.uploaded-files-list ul li {

  margin:0;
  padding: 0;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top */
  }
  
  .loader {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.App {
  text-align: center;
}
body {
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100%;
  height: calc(100vh - 80px);
  background: #fff;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-left{

  margin-top:70px;

}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  opacity: 0;
}
:hover::-webkit-scrollbar {
  opacity: 1;
}

/* Track */
::-webkit-scrollbar-track {
  background: #DDF2FD;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9BBEC8;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #427D9D;
}

.top-navbar-logo {
  font-size: 18px;
  color: #DDF2FD;
  font-weight: 600;
  margin-right: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-inline {
  display: flex;
  align-items: center;
}

.form-inline>button {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  padding: 15px 20px;
  font-size: 14px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

/* TopNavbar.css */
.top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 15px 20px;
  background-color: #254336;
  /* background-color: #164863; */
  box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.top-navbar-logo a {
  color: #fff;
  -webkit-text-decoration: nrgb(29, 29, 31);
          text-decoration: nrgb(29, 29, 31);
  font-size: 1.5rem;
}

.top-navbar-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}

.top-navbar-links a:hover {
  text-decoration: underline;
}
.main-content {
  position: relative;
  width: 100%;
}
.main-content button:nth-child(3) {
  /* float: left; */
  top:120px;
  /* margin-top: 130px; */
  /* margin-left: 18px; */
}
/* .tl-nav-wrap {
  display: flex;
  align-items: center;
} */
/* form.main-search {
  position: relative;
  display: inline-block;
  left: 0;
  right: 0;
  z-index: 99999;
  max-width: 80%;
  margin: 0 auto;
  width: 400px;
} */

.main-search {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  
}

.form-inline {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 5px 10px;
  /* width: 500px; */
  background-color: #6B8A7A;

}
input[type="text"]{
  border: none;
  outline: none;
  flex: 1 1;
  padding-left: 10px;
}
.category-button{
  border-radius:25px ;
  /* border-bottom-left-radius:10px ; */
  background-color: #254336;
  padding:10px 25px;
  margin-right: 5px;
  
}
.category-tag {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  /* background-color: #4caf50; */
  background-color: #254336;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  height: 30px;
}

.main-search .search-btn {
  /* border: 1px solid #427d9d; */
  border:none;
  /* background-color: #427d9d; */
  background-color: #6B8A7A;
  background-image: url('/search-interface-symbol.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-left: 0;
  padding: 15px 30px;
  height: 43px;
  background-size: 16px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
}
.main-search .search-btn:hover {
  background-image: url('/search-interface-symbol-dark.png');
}
.main-search .form-inline input  {
  border-right: 0;
  /* border-radius: 0 !important; */
  /* border: 1px solid #427d9d !important; */
  border: none;
  font-size: 14px;
  padding: 10px 15px !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  /* width: 100%; */
  /* background-color: #427d9d; */
  background-color: #6B8A7A;
  color: #DDF2FD;
}
.main-search .form-inline input::placeholder {
  color: #DDF2FD;
}
.sub-sidebar-wrap ul {
  list-style-type: none;
  padding-left: 0;
}
.sub-sidebar-wrap ul li {
text-align: left;
}

.formcheck {
  text-decoration: none;
  list-style-type: none;
  white-space: inherit;
  padding-left: 0;
  text-align: left;
  margin: 10px; /* Add this line to remove extra space */
  padding: 50; /* Add this line to remove extra space */
  width: 15vh;
}


.formcheck a{
  text-decoration: none;
color: #1b7fb4;
/* margin: 10px; */

}

/* .formcheck .active{
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
} */

.sidebar-overflow{
  height: 87vh;
  overflow: scroll;
  /* padding:0; */
}

.sidebar-overflow ul{
  list-style-type: inherit;
  padding: 0;
  margin: 0;
}
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.app {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.select-element select{
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}
.btn-secondary {
  border: 1px solid #d5e6ff;
  border-radius: 30px;
  font-size: 14px;
  background-color: #fff;
  color: #404040;
  display: inline-block;
  padding: 10px 25px;
  transition: all 300ms ease-in-out;
}
.btn-secondary:hover {
  border-color: #ff6961;
}

.leaflet-draw-toolbar a {
  font-size: 50px;
  width: 50px; /* Adjust width */
  height: 50px; /* Adjust height */
  /* background-size: 24px 24px; Adjust background size */
}
.leaflet-top .leaflet-draw{
  margin-top:120px;
  margin-left:15px;
}

.disabled {
  pointer-events: none;
  color: gray;
  cursor: not-allowed;
}

.link-flex {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* background-color: blue; */
}

.categories-dropdown {
  position: absolute;
  top:90px;
  left:40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 5px;
  gap: 5px; /* Add unit for gap */
  width: 500px;
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius:10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  animation-name: bounceDownIn; /* Name of the animation */
  animation-duration: 0.5s;
}

.categories-dropdown div {
  /* border: 1PX solid black; */
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.categories-dropdown div:hover {
  background: #f0f0f0;
}

.search-btn {
  margin-left: 10px;
  cursor: pointer;
}

@keyframes bounceDownIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.suggestion-item{

  position: absolute;
  /* top:100px; */
  /* left:20%; */

  grid-gap: 5px;

  gap: 5px; /* Add unit for gap */
  width: 500px;
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius:10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  animation-name: bounceDownIn; /* Name of the animation */
  animation-duration: 0.5s;
}

.suggestions-container ul{
  list-style: none;
}

.suggestions-container ul li{
  width: 200px;
  /* background-color: blue; */
  margin-left: -50px;
  margin-top: 20px;

}

.leaflet-interactive .custom-drop-shadow {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}


.propertyform {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.propertyform.open {
  height: 400px;
  overflow: hidden;
  transition: height 0.3s ease-in;
}

.polygon-label {
  background-color: transparent;
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(240, 236, 236, 0.5);
  /* color:white */
  
}

.icon-container {
  display: inline-block;
  transition: transform 1s ease; /* Smooth rotation transition */
}

.animate {
  animation:  rotate 1s ease forwards;
}
/* 
@keyframes vibrate {
  0% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(-5px);
  }
} */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-container button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #0056b3;
}
.error-message {
  color: #ff0000;
  margin-bottom: 10px;
}
