/* Sidebar.css */
.leftsidebar-container.hidden .main-menu {
  /* transform: translateX(-120px);  */
  margin-left: -120px;
  /* Move sidebar off-screen when hidden */
}
.leftsidebar-container.visible .main-menu {
  /* transform: translateX(0);  */
  margin-left: 0;
  /* Move sidebar back on-screen when visible */
}
.element-list {
  max-height: 90%;
  overflow-y: auto;
}

.home-switch {
  position: absolute;
  top: 65px;
  background-color: #6B8A7A;
  left: 10px;
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 888;
}

.toggle-switch{
  position: absolute;
  top: 10px;
  /* background-color: #164863; */
  background-color: #6B8A7A;
  left: 10px;
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999;
}
.toggle-switch:focus {
  box-shadow: none;
  outline: 0;
}
.load-map-btn {
  padding: 10px 20px;
  /* background: #164863; */
  background-color: #6B8A7A;
  color: #fff;
  /* margin: 10px 0; */
  border-radius: 30px;
  font-size: 14px;
}
.load-map-btn:disabled {
  background: #cdcdcd;
  cursor: not-allowed;
}
.sidebar-visible{
  position: relative;
}
.sidebar .main-menu {
  max-width: 100px;
  width: 92px;
  position: relative;
  z-index: 9999;
  /* background: #427D9D; */
  background-color: #6B8A7A;
  height: calc(100vh - 80px);
  padding: 10px 0px;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  overflow-y: auto;
  /* overflow-x: hidden; */
}
/* .sidebar.visible + .main-content { */
  /* margin-left: 120px; */
/* } */
.sidebar.hidden + .main-content {
  margin-left: 0;
}
.sidebar.hidden .sub-sidebar-wrap {
  /* transform: translateX(-250px); */
  margin-left: -250px;
}
.sidebar button{
  border: none;
}
.bars-icon{
  /* width: 35px; */
  max-width: 32px;
  border: none;
}
/* .leftsidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 120px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
} */

/* ul li {
    width: 180px;
    height: auto;
    display: flex;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.5em;
    background: rgb(43, 43, 43);
    position: relative;
    color: white;
    border-radius: 5px;
} */


/* Add more styling as needed */

.leftsidebar,
.rightsidebar { 
  display: flex;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;}
.leftsidebar-menu,
.rightsidebar-menu {
  padding-left: 0;
  list-style-type: none;
  text-align: left;
  margin-bottom: 0;
}
.leftsidebar-menu .sidebar-icon,
.rightsidebar-menu .sidebar-icon {
  margin-bottom: 10px;
  /* filter: grayscale(1); */
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
}
.leftsidebar-menu > li,
.rightsidebar-menu > li { 
  border-bottom: 1px solid rgb(221 242 253 / 40%);
}
.leftsidebar-menu > li > a,
.rightsidebar-menu > li > a { 
  text-decoration: none;
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 25px 10px;
  display: block;
}
.leftsidebar-menu > li > a > span,
.rightsidebar-menu > li > a > span,
.leftsidebar-menu > li >  span,
.rightsidebar-menu > li >  span {
  text-decoration: none;
  color: #DDF2FD;
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.leftsidebar-menu > li.active .sidebar-icon,
.leftsidebar-menu > li:hover .sidebar-icon,
.rightsidebar-menu > li.active .sidebar-icon,
.rightsidebar-menu > li:hover .sidebar-icon {
  filter: grayscale(0);
}
.leftsidebar-menu > li.active a > span,
.leftsidebar-menu > li:hover a > span,
.rightsidebar-menu > li.active a > span,
.rightsidebar-menu > li:hover a > span  {
  color: #DDF2FD;
}
.leftsidebar-menu > li > a::before, 
.rightsidebar-menu > li > a::before  {
  position: absolute;
  content: "";
  width:5px;
  border-radius: 10px;
  background-color: #DDF2FD;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 80%;
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.rightsidebar-menu >li > a::before {
  left: unset;
  right: 0;
}
.leftsidebar-menu > li.active > a::before, 
.rightsidebar-menu > li.active > a::before {
  opacity: 1;
}
.sub-sidebar-wrap {
  width: 250px;
  /* background: #DDF2FD; */
  background: #faffff;
  z-index: 999;
  position: relative;
  top: 0;
  /* left: 120px; */
  /* transform: translateX(-250px); */
  border-left: 1px solid #f3f3f3;
  box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
  height: calc(100vh - 80px);
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  overflow-y: auto;
  opacity: 0;
  margin-left: -250px;
}
.leftsidebar .sub-sidebar-wrap {
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.rightsidebar .sub-sidebar-wrap {
  right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.leftsidebar .sub-sidebar-wrap.visible {
  left: 0;
  margin-left: 0;
  /* width: 250px; */
  opacity: 1;
}
.rightsidebar .sub-sidebar-wrap.visible {
  right: 0;
  margin-left: 0;
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 1;
}
.sub-menu > ul > li {
  padding: 2px 0;
}
.sub-menu > ul > li > a, .sub-menu > ul > li > li > a { 
  font-size: 14px;
  color: #164863;
  display: block;
  font-weight: 400;
  position: relative;
  text-decoration: none;
  padding: 10px 5px;
  border-radius: 7px;
}
.sub-menu ul li > a:hover {
  color: #427D9D;
}

/* Accordion css */
.accordion-content {
  height: 0;
  padding-left: 25px;
  position: static;
  transition: height 300ms ease-in-out;
  -webkit-transition: height 300ms ease-in-out;
  -o-transition: height 300ms ease-in-out;
  -moz-transition: height 300ms ease-in-out;
  overflow: hidden;
}
/* .sub-sidebar-wrap .accordion { */
  /* margin-bottom: 10px; */
/* } */
.accordion .accordion-header {
  display: flex;
  cursor: pointer;
  padding: 5px 0;
  justify-content: space-between;
}
.accordion .accordion-header .toggle{
  width: 100%;
}
.accordion .accordion-header h3 {
  width: 100%;
 font-size: 14px;
 font-weight: 400;
 margin-bottom: 0;
 color: #164863;
 white-space: nowrap;
 overflow: hidden;
}
.accordion .accordion-header h3 > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
}
.accordion .accordion-header:hover h3 {
  color: #427D9D;
}
.sub-menu .accordion.open .accordion-content {
  height: auto;
}
.accordion .arrow {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.accordion .arrow {
  background-image: url('/chevron.png');
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
}
.accordion.open .arrow.open {
  transform: rotate(90deg);
  transform-origin: center;
}
.sub-menu .accordion-content ul {
  padding-left: 0;
  margin-bottom: 15px;
  padding-top: 7px;
}
.sub-menu .accordion-content ul li a,.sub-menu ul li div a , .sub-menu ul li li a {
  text-decoration: none;
  color: #427D9D;
  font-size: 14px;
  padding: 4px 5px;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  line-height: 1.4;
  margin-bottom: 2px;
}

.sub-menu .accordion-content ul li{

  display: flex;
  justify-content: space-between;

}

.sub-menu .accordion-content ul li a:hover {
  color: #164863;
}
.sub-menu .accordion-content ul li a.active,.sub-menu ul li div a.active, .sub-menu > ul > li > a.active,.sub-menu > ul > li > li > a.active, .sub-menu .accordion-content ul li.active, .sub-menu > ul > li.active,.sub-menu ul li li a.active {
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
  padding: 5px 15px 5px 15px;

}

.accordion-content div{
  margin-top: 10px;

}
.accordion-content div  a.active{
  /* background-color: black; */
  width:100%;
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
  padding: 5px 15px 5px 15px;
}

.sub-menu > ul > li > div > a, .sub-menu > ul > li > a.active, .sub-menu ul li li a.active{
width: 100%;
margin-right: 5px;
  padding: 8px 40px 8px 8px;

}
.sub-menu .accordion-content ul li a.active::before,.sub-menu > ul > li > li > a.active::before
/* .sub-menu > ul > li > a.active::before  */
{
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  left: -18px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #164863;
}
.sub-menu > ul > li > a.active::before {
  left: -10px;
}
button.close-submenu {
  /* position: absolute; */
  margin: 30px auto 10px;
  background-color: transparent;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  border: 1px solid #9BBEC8;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
button.close-submenu:hover {opacity: 1;}
.rightsidebar {
  position: relative;
  right: 0;
  top: 0;
  z-index: 99999999;
  background: #fff;
}
.rightsidebar .sub-sidebar-wrap.sub-menu.visible + .main-menu {
  border-left: 1px solid #DDF2FD;
}
.leftsidebar .sub-sidebar-wrap li > a > img, .accordion-content > ul > li > a > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 10px;
}
button.close-submenu > img {
  display: block;
  margin: 0 auto;
  width: 25px;
}
.leftsidebar-container.hidden .sub-menu.visible{
  margin-left: -250px;
}
.sub-menu-title {
  border-bottom: 1px solid rgb(155 190 200 / 50%);
  padding-bottom: 15px;
}
.sub-menu-child {
  border-bottom: 1px solid rgb(155 190 200 / 50%);
  padding-bottom: 10px;
}

.sub-menu-child .label-name{
  color: #586376;
  font-size: 12px;
  font-weight: 600;
}

span.selected-item-label {
  font-size: 11px;
    color: #818181;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline-block;
    /* width: 63px; */
    white-space: nowrap;
    /* overflow: hidden; */
    padding-right: 0px;
    position: relative;
    margin-left: 3px;
}
/* span.selected-item-label::after {
  position: absolute;
  content: "";
  font-size: 11px;
  height: 100%;
  width: 15px;
  background-color: #DDF2FD;
  color: #818181;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto; opacity: 0;
} */

.diagram-tbl-chart {
  width: 90%;
}
.diagram-tbl-chart td {
  padding: 0 5px;
  font-size: 12px;
}
.diagram-tbl-chart > tbody>tr>td:nth-child(2) {
  width: 35%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-block {
  display: block;
}

.tableData {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
}

.tdData, .thData {
  font-size: 15px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.trData:nth-child(even) {
  background-color: #dddddd;
}
.form-label1{
  width: 100%;
  text-align: left;
}


/* REFRESH  */

.refresh-icon {
  font-size: 1.5rem; /* Adjust size as needed */
  color: #333; /* Icon color */
}

.refresh-icon-container {
  cursor: pointer;
  
}

.refresh-icon-container.spin {
  animation: spin 1s infinite linear; /* Animation duration 1 second */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
