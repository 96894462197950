.container-hide {
  display: none;
  /* Initially hide the container */
}

.container-show {
  display: block;
  /* Show the container when 'visible' class is applied */
}

.pole-show {
  display: block;
  /* Show the container when 'visible' class is applied */
}

.pole-hide {
  display: none;
  /* Show the container when 'visible' class is applied */
}

.custom-icon {
  display: none;
  /* Initially hide the container */
}
.form-inline>input {
  border-radius: 0 !important;
  border: 1px solid #000;
  font-size: 14px;
  padding: 15px 35px !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  width: 100%;
}
.form-inline>input:focus {
  box-shadow: none;
  outline: 0;
}
span.toggle-label {
  margin-left: 3px;
}
#root {
  overflow-x: hidden;
}
body > iframe {
  z-index: 9 !important;
}
.main-content {  
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
}
.custom-tooltip {
  background-color: #f9f9f9;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
}
.leaflet-header, .leaflet-body, .leaflet-footer {
  padding: 15px;
}
.leaflet-body {
  clear: both;
}
.leaflet-body p {
  font-size: 14px;margin-top: 0;
  margin-bottom: 5px;
}
.leaflet-header {
  border-bottom: 1px solid #ddd;
}
.leaflet-footer {
  border-top: 1px solid #ddd;
}
.leaflet-popup-close-button {
  font-size: 24px;
    margin: 10px;
}
.modal-thumb { 
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  float: left;
}
.info-wrap {
  display: inline-block;
}
.modal-thumb > img {
  max-width: 90px;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.leaflet-popup-content {
  margin: 0 !important;
  width: 100% !important;
  min-width: 320px;
  max-width: 100%;
}
.leaflet-header > h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.btn-custom {
  border: 0;
  white-space: nowrap;
  padding: 7px 15px;
  border-radius: 15px;
  display: inline-block;
  background: #427d9d;
  color: #ddf2fd;
  margin: 0 1px;
  font-size: 13px;
  line-height: 1;
  transition: all 300ms ease-in-out;
}
.btn-custom:hover {
  background-color: #164863;
  color: #fff;
}
.btn-custom:focus, .btn-bordered:focus, .btn-custom:active, .btn-bordered:active{ outline: none;}
.btn-bordered {
  border: 1px solid #427d9d;
    white-space: nowrap;
    padding: 7px 15px;
    border-radius: 5px;
    display: inline-block;
    background: #ddf2fd;
    color: #427d9d;
    margin: 0 1px;
    font-size: 13px;
    line-height: 1;
}
.info-wrap h6 {
  font-weight: 600;
  font-size: 16px;
}
table.diagram-tbl-chart img {
  width: 26px;
}